<template>
  <v-chip
    :to="link && item[itemValue]? `${baseUrl}?id=${item[itemValue]}` : undefined"
    :color="color"
    :small="small"
  >

    <d-image
      v-if="image && item[itemImage]"
      :value="item[itemImage]"
      :item-src="itemImageSrc"
      class="shrink mr-2"
      color="transparent"
      width="20"
      height="20"
      contain
    />

    {{ item[itemText] || '' }}

  </v-chip>
</template>

<script>
import DImage from '@/components/Image';

export default {
  components: { DImage },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    color: String,
    baseUrl: String,
    image: Boolean,
    itemValue: {
      type: String,
      default: 'id'
    },
    itemText: {
      type: String,
      default: 'name'
    },
    itemImage: {
      type: String,
      default: 'picture'
    },
    itemImageSrc: {
      type: String,
      default: 'thumbnailUrl'
    },
    link: Boolean,
    small: Boolean
  }
}
</script>
