<template>
  <div :class="classes">
    <component :is="transition" leave-absolute>
      <d-image
        v-bind="$attrs"
        :key="index"
        :value="internalValue[index]"
        :color="color"
        :base-url="baseUrl"
        :item-image="itemImage"
        :broken-icon="brokenIcon"
        :placeholder="computedPlaceholder"
        :preview="uploading ? undefined : preview"
        :lazy-src="uploading ? preview : undefined"
        :loading="loading"
        :error="!valid"
        @click="click"
      >
        <template v-slot:loading>
          <slot name="loading"/>
        </template>

        <template v-slot:placeholder>
          <slot name="placeholder"/>
        </template>

        <template v-slot:error>
          <slot name="error"/>
        </template>

        <file-field
          ref="input"
          :rules="computedRules"
          :upload="onUpload"
          :accept="accept"
          :auto-upload="autoUpload"
          :error-message="computedErrorMessage"
          :multiple="multiple"
          @change="onChange"
          @valid="onValid"
        />

      </d-image>
    </component>

    <v-hover v-if="multiple && internalValue.length">
      <template v-slot:default="{ hover }">
        <div class="image-field--actions absolute layer">

          <v-scale-transition origin="center center 0">
            <btn
              v-if="hover"
              class="mx-1"
              icon="mdi-pencil"
              width="40"
              height="40"
              @click="edit"
              :title="$t('btn.edit')"
              fab
            />
          </v-scale-transition>

          <v-scale-transition origin="center center 0">
            <btn
              v-if="hover"
              class="mx-1"
              color="primary"
              elevation="2"
              icon="mdi-plus"
              width="40"
              height="40"
              @click="add"
              :title="$t('btn.add')"
              fab
            />
          </v-scale-transition>

        </div>
      </template>
    </v-hover>

    <template v-if="multiple && internalValue.length > 1">

      <btn
        class="image-field--prev"
        icon="mdi-chevron-left"
        @click="prev"
        color="white"
        :disabled="!hasPrev"
        :dark="false"
        absolute fab
      />

      <btn
        class="image-field--next"
        icon="mdi-chevron-right"
        @click="next"
        color="white"
        :disabled="!hasNext"
        :dark="false"
        absolute fab
      />

      <ul class="image-field--bullets pa-0">
        <li
          v-for="(_,i) in internalValue"
          :class="{ 'elevation-1': true, 'selected': i === index }"
          :key="i"
        />
      </ul>

    </template>

    <btn
      v-if="clearable && !loading && internalValue.length"
      class="image-field--clear"
      :title="computedClearTitle"
      width="30"
      height="30"
      @click="clear"
      absolute fab
      top right
    >
      <v-icon size="20">
        {{clearIcon}}
      </v-icon>
    </btn>

  </div>
</template>

<script>
import { VScrollXTransition, VScrollXReverseTransition } from 'vuetify/lib/components/transitions';
import DImage from '@/components/Image';
import FileField from '../FileField';
import { deepEqual, wrapInArray } from 'vuetify/lib/util/helpers';
import { IMAGE_BASE_URL } from '@/utils/constants';

export default {
  components: {
    VScrollXTransition,
    VScrollXReverseTransition,
    FileField,
    DImage
  },
  props: {
    value: null,
    baseUrl: {
      type: String,
      default: IMAGE_BASE_URL
    },
    itemImage: [ String, Function ],
    errorMessage: String,
    disabled: Boolean,
    clearable: Boolean,
    multiple: Boolean,
    autoUpload: {
      type: Boolean,
      default: true
    },
    upload: Function,
    accept: {
      type: String,
      default: 'image/jpeg, image/png'
    },
    rules: Array,
    placeholder: String,
    color: String,
    clearTitle: String,
    clearIcon: {
      type: String,
      default: 'mdi-trash-can-outline'
    },
    brokenIcon: {
      type: String,
      default: 'mdi-image-off'
    }
  },
  data: () => ({
    internalValue: [],
    index: 0,
    transition: 'v-scroll-x-reverse-transition',
    adding: false,
    loading: false,
    uploading: false,
    error: false,
    valid: true
  }),
  computed: {
    classes() {
      return {
        'image-field': 1,
        'image-field--disabled': this.disabled
      }
    },
    computedErrorMessage() {
      return this.errorMessage || this.$t('inputs.picture.uploadError');
    },
    computedClearTitle() {
      return this.clearTitle || this.$t('inputs.picture.clear');
    },
    computedPlaceholder() {
      if ( this.placeholder ) return this.placeholder;
      if ( this.multiple ) return this.$t('inputs.picture.multiplaceholder');
      return undefined;
    },
    computedRules() {
      return ( this.rules || [] ).concat([
        this.uploadingRule
      ]);
    },
    preview() {
      return this.internalValue[ this.index ]
        ? this.internalValue[ this.index ].preview
        : undefined;
    },
    hasPrev() {
      return this.index - 1 >= 0;
    },
    hasNext() {
      return this.index + 1 < this.internalValue.length;
    }
  },
  watch: {
    value( value, old ) {
      if ( deepEqual( value, old )) return;
      this.set( value );
    },
    internalValue( value ) {
      this.index = Math.min( this.index, value.length );
      this.$emit( 'input', this.multiple ? value : value[ this.index ] );
    },
  },
  methods: {
    set( value = this.value ) {
      this.internalValue = wrapInArray( value );
    },
    edit(e) {
      this.$refs.input && this.$refs.input.click(e);
    },
    click(e) {
      this.$emit( 'click', e );
      if ( ! this.multiple || ! this.internalValue.length ) this.edit(e);
    },
    clear(e) {
      this.$refs.input && this.$refs.input.clear( e, this.index );
      if ( this.internalValue[ this.index ]) {
        this.internalValue.splice( this.index, 1 );
        this.index = Math.max( Math.min( this.index, this.internalValue.length - 1 ), 0 );
      }
    },
    add(e) {
      this.adding = true;
      this.edit(e);
    },
    goTo( index ) {
      index = Math.max( Math.min( index, this.internalValue.length - 1 ), 0 );
      this.transition = index > this.index
        ? 'v-scroll-x-reverse-transition'
        : 'v-scroll-x-transition';
      this.index = index;
    },
    prev() {
      this.goTo( this.index - 1 );
    },
    next() {
      this.goTo( this.index + 1 );
    },
    startUpload() {
      this.$refs.input && this.$refs.input.startUpload();
    },
    uploadingRule( msg ) {
      const files = this.internalValue.filter( i => i.file );
      return !files.length || this.$t('inputs.picture.rules.uploading') || msg;
    },
    uploadHandler( formData ) {
      return this.$store.dispatch( 'api/upload/images', {
        silence: true,
        formData
      }).then( images => {
        if ( this.baseUrl ) {
          images.forEach( image => {
            image.thumbnailUrl = image.thumbnailUrl.slice( image.thumbnailUrl.lastIndexOf('/') + 1 );
            image.url = image.url.slice( image.url.lastIndexOf('/') + 1 );
          });
        }
        return images;
      });
    },
    onUpload( data ) {
      return new Promise(( resolve, reject ) => {

        this.uploading = this.loading = true;

        const files = this.internalValue.filter( i => i.file );
        const upload = this.upload || (() => this.uploadHandler( data ));

        Promise.resolve( upload( data, files.map( i => i.file ))).then( images => {
          this.internalValue.splice( this.index, images.length, ...images );
          this.set( this.internalValue );
          resolve();
        })
        .catch( reject )
        .finally(() => {
          this.uploading = this.loading = false;
        });
      });
    },
    onChange( files ) {

      this.index = this.adding ? this.internalValue.length : this.index;
      this.adding = false;

      this.internalValue.splice(
        this.index, 1, ...files.map( file => {
          try {
            return { file, preview: URL.createObjectURL( file ) };
          } catch(e) {
            return { file };
          }
        })
      );
    },
    onValid( value ) {
      this.valid = value;
      this.$emit( 'valid', value );
    }
  },
  beforeMount() {
    this.set( this.value );
  }
}
</script>

<style>
.image-field {
  position: relative;
  overflow: hidden;
}
.image-field:not(.image-field--disabled) {
  cursor: pointer;
}
.image-field.image-field--disabled {
  opacity: .65;
}
.image-field .file-field {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.image-field .file-field .v-input__slot {
  display: none !important;
}
.image-field .v-text-field__details {
  padding: 8px !important;
}
.image-field .v-text-field__details .v-messages__message {
  padding: 8px;
  background-color: var(--v-error-base);
  color: white;
  width: 100% !important;
}
.image-field .image-field--prev {
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}
.image-field .image-field--next {
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}
.image-field--bullets {
  list-style: none;
  position: absolute;
  bottom: 8px;
  left: 8px;
  right: 8px;
  text-align: center;
}
.image-field--bullets > li {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
  margin: 0 4px;
  cursor: pointer;
  opacity: .5;
}
.image-field--bullets > li:hover {
  opacity: .75;
}
.image-field--bullets > li.selected {
  opacity: 1;
}
.image-field--actions {
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-field--clear {
  top: 8px !important;
}
</style>
