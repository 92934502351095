<template>
  <pagination
    v-bind="$props"
    v-on="$listeners"
    class="custom-pagination"
    :class="{ '--block': block }"
  >
    <template v-slot:item="{ value, current, on }">
      <btn
        class="mx-1"
        :color="current ? 'primary' : 'black'"
        :fab="current"
        :icon="!current"
        width="24" height="24" small
        v-on="on"
      >
        {{ value }}
      </btn>
    </template>
    <template v-slot:next="scope">
      <btn
        v-on="scope.on"
        :disabled="scope.disabled"
        :icon="scope.icon"
        :fab="block"
        small
      />
    </template>
    <template v-slot:prev="scope">
      <btn
        v-on="scope.on"
        :disabled="scope.disabled"
        :icon="scope.icon"
        :fab="block"
        small
      />
    </template>
  </pagination>
</template>

<script>
import Pagination from '@/components/Pagination';

export default {
  components: { Pagination },
  props: {
    ...Pagination.props,
    block: Boolean,
    disabled: Boolean,
    totalVisible: {
      type: [ Number, String ],
      default: 10
    }
  }
}
</script>

<style>
.custom-pagination.--block .v-pagination {
  position: relative;
}
.custom-pagination.--block .v-pagination > li:first-child {
  position: absolute;
  left: 0;
}
.custom-pagination.--block .v-pagination > li:last-child {
  position: absolute;
  right: 0;
}
</style>
