<template>
  <div
    v-if="isAdminLayout"
    :class="classes"
    @click="onClickOutside"
  >
    <div class="shrink">
      <v-sheet :height="headerHeight">
        <v-sheet
          v-if="headerExtended"
          class="d-flex flex-nowrap align-center"
          height="56"
        >

          <slot name="header"/>

        </v-sheet>
        <v-sheet
          class="d-flex flex-nowrap align-center"
          height="56"
        >

          <slot
            v-if="!headerExtended"
            name="header"
          />

          <btn
            v-if="isMobile && selectable"
            class="ml-3"
            color="primary"
            @click.stop="isSelectable = !isSelectable"
            :text="!isSelectable"
            small
          >
            {{ $t( isSelectable ? 'btn.unselect' : 'btn.select') }}
          </btn>

          <btn
            v-if="isMobile && isSelectable"
            class="ml-2"
            :color="`primary ${isAllSelected ? 'lighten-2' : ''}`"
            :text="!isAllSelected"
            @click.stop="$refs.table.selectAll"
            small
          >
            {{ $t( isAllSelected ? 'btn.unselectAll' : 'btn.selectAll') }}
          </btn>

          <v-spacer/>

          <btn
            class="mx-6"
            :icon="isMobile"
            :text="!isMobile"
            :title="$t('btn.filters')"
            @click.stop="drawer = !drawer"
          >
            <v-badge
              :value="hasFilters"
              color="error"
              :offset-x="isMobile ? 8 : 14"
              offset-y="6"
              dot
            >
              <v-icon :left="!isMobile">
                mdi-filter
              </v-icon>
            </v-badge>
            {{ isMobile ? '' : $t('btn.filters') }}
          </btn>


        </v-sheet>
      </v-sheet>
      <v-divider/>
    </div>
    <div class="grow relative">
      <data-table
        ref="table"
        class="layer absolute body-2"
        height="100%"
        :items="items"
        :headers="visibleHeaders"
        :selected="selected"
        :selectable="isMobile ? isSelectable : selectable"
        :sort-by="sortBy"
        :min-width="minWidth"
        :hide-header="isMobile && hideTableHeader"
        :hide-global-checkbox="isMobile"
        @selected="onSelect"
        @selected:all="isAllSelected = $event"
        @click:item="onClickItem( $event, isMobile && !isSelectable )"
        @dblclick:item="onClickItem( $event, !isMobile && !isSelectable )"
        @sort-by="onSort"
        fixed-header
        single-sort
      >

        <v-card class="table-card layer"/>

        <template v-slot:item.value.edit>
          <v-icon>
            mdi-chevron-right
          </v-icon>
        </template>

        <template
          v-for="name in DataTableSlots"
          v-slot:[name]="scope"
        >
          <slot :name="name" v-bind="scope"/>
        </template>

      </data-table>

    </div>

    <v-navigation-drawer
      v-model="drawer"
      class="filters-drawer"
      width="400"
      touchless
      temporary
      absolute
      right
    >
      <v-divider/>
      <v-app-bar
        class="shrink"
        color="white"
        scroll-target="#filters-body"
        elevate-on-scroll
        dense
      >

        <v-icon color="black" class="mr-2" small>
          mdi-filter
        </v-icon>

        <h4 class="font-weight-regular">
          {{ $t('btn.filters') }}
        </h4>

        <v-spacer/>

        <btn @click="cleanFilters" x-small>
          {{ $t('btn.clean') }}
        </btn>

        <btn
          class="ml-1"
          icon="mdi-close"
          color="black"
          @click="drawer = false"
        />

      </v-app-bar>
      <div class="grow relative">
        <div
          id="filters-body"
          class="layer absolute overflow-y-auto pa-4"
          @keypress.enter="applyFilters()"
        >

          <template v-for="filter in visibleFilters">
            <component
              v-bind="filter"
              v-model="lazyFilters[filter.key]"
              :is="filter.is"
              :key="filter.key"
            />
          </template>

        </div>
      </div>
      <v-divider/>
      <v-footer color="white">
        <btn @click="applyFilters()" color="black" dark block>
          {{ $t('btn.apply') }}
        </btn>
      </v-footer>
    </v-navigation-drawer>

    <slot/>

    <m-dialog
      v-if="popup"
      v-model="form"
      v-bind="computedPopup"
      @click:accept="onSave"
    >
      <slot name="form"/>
      <template v-slot:title>

        <btn
          v-if="isMobile"
          icon="mdi-chevron-left"
          class="mr-2"
          @click="form = false"
        />

        {{ popup.title }}

      </template>
    </m-dialog>

    <remove-dialog
      v-model="removing"
      v-bind="dialogProps"
      v-on="dialogListeners"
      @removed="onRemove"
      :items="selected"
      :api="api"
    />

    <template v-if="showFooter">

      <v-divider/>

      <v-footer
        v-if="showFooter"
        color="background"
        :class="{ extended: footerExtended }"
        :height="footerHeight"
      >

        <pagination
          v-if="showPagination"
          v-model="page"
          class="grow"
          :length="numPages"
          :block="isMobile"
        />

        <div
          v-if="showFooterSection"
          class="footer-section"
        >

          <btn
            v-if="selectable && selected.length"
            icon-left="mdi-trash-can-outline"
            color="black"
            @click.stop="removeItems"
            text
          >
            {{ $t('btn.remove') }}
          </btn>

          <slot name="footer"/>

        </div>

      </v-footer>
    </template>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable';
import Pagination from '@/components/CustomPagination';
import RemoveDialog from '@/components/RemoveDialog';
import TextField from '@/components/form/TextField';
import SelectField from '@/components/form/SelectField';
import NumberField from '@/components/form/NumberField';
import DateField from '@/components/form/DateField';
import ApiField from '@/components/form/ApiField';
import RangeDateField from '@/components/form/RangeDateField';
import Checkbox from '@/components/form/Checkbox';
import { deepEqual } from 'vuetify/lib/util/helpers';
import { createFilters, cleanObject } from '@/utils';
import { mapState } from 'vuex';

const splitComma = str => (
  str.replaceAll('\\,','{{*}}')
    .split(',')
    .map( s => s.replaceAll('{{*}}',','))
  );

export default {
  components: {
    DataTable,
    Pagination,
    RemoveDialog,
    TextField,
    SelectField,
    NumberField,
    DateField,
    ApiField,
    RangeDateField,
    Checkbox
  },
  props: {
    value: Boolean,
    api: String,
    method: {
      type: String,
      default: 'page'
    },
    headers: Array,
    filters: Array,
    useFilters: [ String, Object ],
    selectable: Boolean,
    remove: Function,
    edit: Function,
    compute: Function,
    minWidth: [ Number, String ],
    hideTableHeader: {
      type: Boolean,
      default: true
    },
    headersOnMobile: {
      type: Array,
      default: () => []
    },
    popup: Object,
    newPopup: Boolean,
    save: Function,
    dialogProps: {
      type: Object,
      default: () => ({})
    },
    ignoreStatus: Boolean,
    removedStatus: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      scroll: false,
      dialog: false,
      removing: false,
      drawer: false,
      noManageQuery: false,
      isSelectable: false,
      isAllSelected: false,
      form: !!this.value,
      selected: [],
      lazyFilters: {},
      appliedFilters: {}
    }
  },
  computed: {
    ...mapState( 'app', [ 'loading', 'isMobile' ]),
    classes() {
      return {
        'table-view': true,
        '--scroll': this.scroll,
        '--mobile': this.isMobile
      };
    },
    isAdminLayout() {
      return this.$store.state.app.layout === 'admin';
    },
    apiState() {
      return this.$store.state.api[ this.api ] || {};
    },
    page: {
      get() {
        return this.apiState.page || 1;
      },
      set( page ) {
        this.$store.commit( `api/${this.api}/set`, { page });
      }
    },
    numPages() {
      return this.apiState.numPages || 1;
    },
    order: {
      get() {
        return this.apiState.order;
      },
      set( order ) {
        this.$store.commit( `api/${this.api}/set`, { order });
      }
    },
    ascending: {
      get() {
        return this.apiState.ascending;
      },
      set( ascending ) {
        this.$store.commit( `api/${this.api}/set`, { ascending });
      }
    },
    sortBy() {
      if ( ! this.order ) return [];
      return [(this.ascending ? '-' : '') + this.order ];
    },
    items() {
      const data = this.apiState.data || [];
      if ( this.compute ) return this.compute( data ) || [];
      return data;
    },
    visibleHeaders() {
      return this.headers.filter( head => {
        if ( head.hide ) return false;
        if ( this.isMobile && head.mobile !== true ) return false;
        return true;
      }).concat( this.isMobile ? [{
        key: 'edit',
        width: 1
      }] : []);
    },
    visibleFilters() {
      return this.headers.filter( head => {
        return head.filter !== false;
      }).map( head => ({
        is: 'text-field',
        key: head.key,
        label: head.text,
        ...head.filter,
        filterFormat: true
      }));
    },
    extraFilters() {

      let filters = this.useFilters || {};

      if ( typeof this.useFilters === 'string' ) {
        filters = {};
        this.useFilters.split(',').forEach( key => {
          key = key.trim();
          filters[key] = key;
        });
      }

      Object.keys( filters ).forEach( key => {
        if ( typeof filters[key] === 'string' ) {
          filters[key] = { key: filters[key] }
        }
      });

      return filters;
    },
    visibleFiltersMap() {
      return this.visibleFilters.reduce(( obj, filter ) => {
        obj[filter.key] = filter;
        return obj;
      },{});
    },
    allFiltersMap() {
      return {
        ...this.extraFilters,
        ...this.visibleFiltersMap
      };
    },
    computedAppliedFilters() {
      const filters = this.filters || [];
      return createFilters( this.appliedFilters )
        .filter( a => !filters.some( b => a.field === b.field ));
    },
    computedFilters() {
      const filters = this.filters || [];
      return filters.concat( this.computedAppliedFilters );
    },
    hasFilters() {
      return !!( this.computedAppliedFilters
        .filter( a => this.visibleFiltersMap[a.field])
        .length
      );
    },
    dialogListeners() {
      return this.remove ? { 'click:accept': this.remove } : {};
    },
    DataTableSlots() {
      return Object.keys( this.$scopedSlots )
        .filter( key => !['header','footer','default'].includes( key ))
    },
    showPagination() {
      return this.numPages > 1;
    },
    showFooterSection() {
      return !!this.$slots.footer || this.selectable;
    },
    showFooter() {
      return this.showFooterSection || this.showPagination;
    },
    headerExtended() {
      return this.selectable && this.isMobile && !!this.$slots.header;
    },
    headerHeight() {
      return this.headerExtended ? 112 : 56;
    },
    footerExtended() {
      return this.showPagination && this.showFooterSection;
    },
    footerHeight() {
      if ( ! this.showFooter ) return 0;
      return this.footerExtended ? 96 : 48;
    },
    computedPopup() {
      const props = this.popup || {};
      return {
        maxWidth: 480,
        retainFocus: false,
        persistent: true,
        scrollable: true,
        ...props,
        headerHeight: this.isMobile ? 60 : props.headerHeight,
        footerHeight: this.isMobile ? 60 : props.footerHeight,
        fullscreen: this.isMobile || !!props.fullscreen,
        transition: this.isMobile
          ? 'scroll-x-reverse-transition'
          : props.transition
      }
    }
  },
  watch: {
    $route() {
      this.manageQuery();
      this.refresh(1);
    },
    api() {
      this.refresh(1);
    },
    page() {
      this.refresh();
    },
    order() {
      this.refresh();
    },
    ascending() {
      this.refresh();
    },
    computedFilters( value, old ) {
      if ( deepEqual( value, old )) return;
      this.refresh(1);
    },
    value( value ) {
      this.form = !!value;
    },
    form( value ) {
      ! value && this.removeIdQuery();
      this.$emit( 'input', value );
    },
    isMobile( value ) {
      !value && ( this.isSelectable = false );
    },
    isSelectable( value ) {
      if ( this.isMobile && !value ) {
        this.selected = [];
      }
    },
    hasFilters() {
      this.$emit( 'filters', this.computedAppliedFilters );
    }
  },
  methods: {
    getTableWrap() {
      const { table } = this.$refs;
      if ( ! table ) return;
      return table.$el.children[0];
    },
    hasScroll() {
      const wrapp = this.getTableWrap();
      wrapp && window.requestAnimationFrame(() => {
        this.scroll = wrapp.clientHeight < wrapp.scrollHeight;
      });
    },
    cleanFilters() {
      this.lazyFilters = {};
      this.applyFilters(true);
    },
    applyFilters( persist ) {

      this.appliedFilters = cleanObject({ ...this.lazyFilters });
      this.drawer = !!persist;

      this.noManageQuery = true;
      this.$router.replace({
        path: this.$route.path,
        params: this.$route.params,
        query: Object.keys( this.appliedFilters ).reduce(( obj, key ) => {
          if ( Array.isArray( this.appliedFilters[key] )) {
            obj[key] = this.appliedFilters[key].join(',');
          } else {
            obj[key] = String( this.appliedFilters[key] ).replace(/,/,'\\,');
          }
          return obj;
        },{
          id: this.$route.query.id
        })
      }).catch(() => {});
    },
    onClickItem( e, emit ) {
      if ( ! emit ) return;
      this.$emit( 'click:item', e );
    },
    onClickOutside(e) {
      const exclude = ['v-navigation-drawer','v-overlay'];
      if ( e.path.some(({ classList }) => {
        return classList && exclude.some( c => classList.contains(c));
      })) return;
      this.unselect();
    },
    onSelect( selected ) {
      this.selected = selected;
      this.$emit( 'selected', selected );
    },
    onSort( value ) {
      const sort = value[0];
      this.order = sort ? sort.value : null;
      this.ascending = sort ? sort.mode === 'asc' : false;
    },
    onRemove() {
      this.unselect( this.selected );
      this.refresh();
    },
    onSave() {
      this.save && this.save();
    },
    manageQuery() {

      if ( this.noManageQuery ) {
        this.noManageQuery = false;
        return;
      }

      const { query } = this.$route;
      let value, apply;

      Object.keys( query ).forEach( key => {

        const filter = this.allFiltersMap[key];

        if ( key === 'id' ) {
          this.$store.dispatch( `api/${this.api}/get`, query ).then( item => {
            if ( ! this.ignoreStatus && item.status === this.removedStatus ) {
              this.removeIdQuery( true );
              this.$store.dispatch( 'app/alert', {
                type: 'error',
                message: this.$t(`${this.api}.notFound`)
              });
            } else {
              this.edit && this.edit( item );
            }
          }).catch(() => {
            this.removeIdQuery( true );
            this.$store.dispatch( 'app/alert', {
              type: 'error',
              message: this.$t(`${this.api}.notFound`)
            });
          });

        } else if ( filter ) {

          value = splitComma(query[key]).filter( Boolean ).map( str => {
            if ( ! isNaN( Number(str))) return Number(str);
            return str;
          });

          if ( value.length > 1 ) {
            this.lazyFilters[filter.key] = value;
            apply = true;
          } else if ( value.length ) {
            this.lazyFilters[filter.key] = value[0];
            apply = true;
          }
        }
      });

      apply && this.applyFilters();
    },
    removeIdQuery( replace ) {
      if ( ! this.$route.query.id ) return;
      this.form = false;
      this.noManageQuery = true;
      this.$router[ replace ? 'replace' : 'push' ]({
        path: this.$route.path,
        params: this.$route.params,
        query: { ...this.$route.query, id: undefined }
      });
    },
    /*** @public ***/
    removeItems() {
      if ( ! this.selected.length ) return;
      this.removing = true;
    },
    /*** @public ***/
    selectAll() {
      this.$refs.table && this.$refs.table.selectAll();
    },
    /*** @public ***/
    select( items, value, single ) {
      this.$refs.table && this.$refs.table.select( items, value, single );
    },
    /*** @public ***/
    unselect( items ) {
      this.$refs.table && this.$refs.table.unselect( items );
    },
    /*** @public ***/
    exportData( page ) {
      page && ( this.page = page );
      if ( this.loading ) return;
      return this.$store.dispatch( `api/${this.api}/export`, {
        page: this.page,
        filters: this.computedFilters,
        order: this.order,
        ascending: this.ascending
      }).catch(() => {
        this.$store.dispatch( 'app/error', {
          message: this.$t('alerts.error')
        })
      });
    },
    /*** @public ***/
    refresh( page ) {
      setTimeout(() => {
        page && ( this.page = page );
        if ( this.loading ) return;
        return this.$store.dispatch( `api/${this.api}/${this.method}`, {
          page: this.page,
          filters: this.computedFilters,
          order: this.order,
          ascending: this.ascending
        }).catch(() => {
          this.$store.dispatch( 'app/error', {
            message: this.$t('alerts.error')
          })
        });
      })
    }
  },
  mounted() {

    // Prevent reload on change view with no admin layout
    if ( ! this.isAdminLayout ) return;

    this.manageQuery();
    this.hasScroll();
    this.refresh();
    window.addEventListener( 'resize', this.hasScroll );
  },
  beforeDestroy() {
    window.removeEventListener( 'resize', this.hasScroll );
  }
}
</script>

<style>
.table-view {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.table-view .data-table {
  z-index: 2;
}
.table-view .v-data-table__wrapper::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  height: 48px;
  width: 100%;
  background-color: var(--v-background-base);
}
.table-view .v-data-table__wrapper::before {
  left: 0;
}
.table-view .data-table > div {
  padding: 0 16px 16px 16px;
}
.table-card {
  position: absolute;
  top: 48px;
  z-index: -1;
}
.table--hide-header .table-card {
  top: 0;
}
.table--hide-header .v-data-table__wrapper::before {
  display: none;
}
.table-view .table--hide-header > div {
  padding-top: 16px;
}
.table-view .footer-section {
  flex: 0 0 100%;
  display: flex;
  align-items: center;
}
.table-view .v-footer.extended > * {
  height: 50%;
  display: flex;
  align-items: center;
}
.table-view .v-footer.extended > nav {
  border-bottom: 1px solid rgba(0,0,0,0.15);
}
.table-view .filters-drawer > div {
  display: flex;
  flex-direction: column;
}
.table-view .v-slide-group__prev,
.table-view .v-slide-group__next {
  display: none !important;
}
</style>
