<template>
  <tr
    tabindex="0"
    :class="classes"
    @click="onClick"
  >
    <td
      v-if="!hideCheckbox"
      class="data-table-item-checkbox"
    >
      <slot name="checkbox" v-bind="item">
        <v-icon small>
          {{ item && item.selected
            ? checkboxActiveIcon
            : checkboxIcon
          }}
        </v-icon>
      </slot>
    </td>
    <td
      v-for="cell in item.row"
      class="pa-3"
      :class="{[`text-${cell.column.align}`]: cell.column.align }"
      :style="{ width: cell.column.width }"
      :key="cell.column.key"
    >
      <slot :name="cell.column.key" v-bind="cell">
        <span v-html="cell.value"/>
      </slot>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'ExplorerItem',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    activeClass: {
      type: String,
      default: '--is-selected'
    },
    hideCheckbox: Boolean,
    checkboxIcon: String,
    checkboxActiveIcon: String,
  },
  data: () => ({
    clicks: 0,
    event: null
  }),
  watch: {
    clicks( num ) {
      num > 1 && this.$emit( 'dblclick', this.event );
    }
  },
  computed: {
    classes() {
      return {
        'data-table-item': true,
        [this.activeClass]: !!this.item.selected
      }
    },
  },
  methods: {
    onClick(e) {
      clearInterval(this.interval);
      this.clicks++;
      this.event = e;
      this.interval = setTimeout(() => this.clicks = 0, 500 );
      this.$emit( 'click', e );
    }
  }
}
</script>

<style>
.data-table-item,
.data-table-item:hover,
.data-table-item:focus {
  background-color: transparent !important;
  cursor: pointer;
}
.data-table-item td {
  position: relative;
}
.data-table-item td > * {
  z-index: 1;
}
.data-table-item td > *:not(a):not(button) {
  cursor: initial;
}
.data-table-item-checkbox {
  width: 0 !important;
}
.data-table-item.--is-selected,
.data-table-item-checkbox .v-icon {
  color: var(--v-primary-base);
}
.data-table-item:hover td:before,
.data-table-item.--is-selected td:before {
  content: '';
  display: block;
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  opacity: .1;
  background-color: var(--v-primary-base);
  z-index: 0;
}
.data-table-item td > * {
  position: relative;
  z-index: 1;
}
.data-table-item.--is-selected td:before {
  opacity: .15;
}
.data-table-item:focus {
  outline-color: rgba(0,0,0,.1);
  outline-offset: -2px;
}
</style>
