<template>
  <div :class="classes">
    <label v-if="label" class="caption">
      {{label}}
    </label>
    <div class="d-flex align-center my-2">
      <date-field
        v-bind="dateProps"
        :value="dates[0]"
        :min="min"
        :max="dates[1]"
        :error="dates[0] == null && invalid"
        :prefix="$t('inputs.rangeDate.from.default')"
        @input="dates = [$event,dates[1]]"
      />
      <time-field
        v-if="time"
        v-bind="timeProps"
        :value="times[0]"
        :error="dates[0] == null && invalid"
        @input="times = [$event,times[1]]"
        placeholder="00:00"
        class="ml-2"
      />
    </div>
    <div class="d-flex align-center mb-4">
      <date-field
        v-bind="dateProps"
        :value="dates[1]"
        :error="dates[1] == null && invalid"
        :min="dates[0]"
        :max="max"
        :prefix="$t('inputs.rangeDate.to.default')"
        @input="dates = [dates[0],$event]"
      />
      <time-field
        v-if="time"
        v-bind="timeProps"
        :value="times[1]"
        :error="dates[1] == null && invalid"
        @input="times = [times[0],$event]"
        placeholder="00:00"
        class="ml-2"
      />
    </div>
  </div>
</template>

<script>
import DateField from '../DateField';
import TimeField from '../TimeField';
import { componentProps } from '@/utils';
import { wrapInArray, deepEqual } from 'vuetify/lib/util/helpers';
import config from './defaults';
import moment from 'moment';

const defaultProps = {
  ...TimeField.props,
  ...DateField.props,
  filterFormat: Boolean,
  time: Boolean,
  timeIcon: String,
  timeFormat: {
    type: String,
    default: 'hh:mm'
  }
};

const isObject = v => v && typeof v === 'object' && !Array.isArray(v);

export default {
  inheritAttrs: false,
  components: { DateField, TimeField },
  props: componentProps( defaultProps, config ),
  data() {
    return {
      dates: [ undefined, undefined ],
      times: [ undefined, undefined ]
    }
  },
  computed: {
    classes() {
      return {
        'range-date-field': true,
        '--time': this.time
      };
    },
    dateProps() {
      return {
        ...Object.keys( this.$props )
        .filter( key => DateField.props[key] )
        .reduce(( obj, key ) => {
          obj[key] = this.$props[key];
          return obj;
        },{}),
        label: null,
        returnDate: false,
        hideDetails: true
      }
    },
    timeProps() {
      return {
        ...Object.keys( this.$props )
        .filter( key => TimeField.props[key] )
        .reduce(( obj, key ) => {
          obj[key] = this.$props[key];
          return obj;
        },{}),
        appendIcon: this.timeIcon,
        format: this.timeFormat,
        returnDate: false,
        hideDetails: true,
        label: null
      }
    },
    invalid() {
      return this.dates.filter( Boolean ).length === 1;
    }
  },
  watch: {
    value: 'setValue',
    dates( dates, old ) {
      if ( deepEqual( dates, old )) return;
      this.emit();
    },
    times( times, old ) {
      if ( deepEqual( times, old )) return;
      this.emit();
    }
  },
  methods: {
    setValue( value = this.value ) {
      if ( this.filterFormat && isObject( value )) {
        this.dates = [ value.start, value.end ];
      } else {
        this.dates = wrapInArray( value ).slice(0,2);
      }
    },
    emit() {

      if ( this.dates[0] == null || this.dates[1] == null ) {
        return this.$emit( 'input', undefined );
      }

      let emit = this.dates.map(( value, i ) => {
        value = moment( value ).startOf('day').valueOf() + ( this.times[i] || 0 );
        if ( this.returnDate && value != null ) value = new Date( value );
        return value;
      });

      if ( this.filterFormat ) {
        emit = { start: emit[0], end: emit[1] };
      }

      this.$emit( 'input', emit );
    }
  },
  beforeMount() {
    this.setValue();
  }
};
</script>

<style>
.range-date-field.--time .text-field {
  width: 50%;
}
</style>
