<template>
  <v-simple-table
    class="data-table"
    :class="classes"
    :style="computedMinWidth"
    v-bind="tableProps"
  >
    <thead v-if="!hideHeader">
      <tr>
        <th
          v-if="selectable && !hideCheckbox"
          class="data-table__checkbox"
        >
          <slot v-if="!hideGlobalCheckbox" name="header.checkbox">
            <v-icon
              @click.stop="selectAll"
              small
            >
              {{ isAllSelected
                  ? checkboxActiveIcon
                  : hasSelected
                    ? checkboxIndeterminateIcon
                    : checkboxIcon
              }}
            </v-icon>
          </slot>
        </th>
        <th
          v-for="( head, index ) in computedHeaders"
          class="pa-3"
          :key="head.key"
          :tabindex="head.sorteable && !disableSort ? 0 : -1"
          :style="{ width: head.width }"
          :class="headerClasses( head, index )"
          @click="toggleSort( $event, head, index )"
          @keypress.enter="toggleSort( $event, head, index )"
        >

          <slot :name="'header.'+head.key" v-bind="head">
            {{ head.text }}
          </slot>

          <v-icon class="data-table__sort-icon" small>
            {{sortIcon}}
          </v-icon>

        </th>
      </tr>
    </thead>

    <tbody v-if="computedItems.length">
      <template v-for="( item, index ) in computedItems">
        <slot name="item" v-bind="item">
          <data-table-item
            :item="item"
            :key="item.id || `item-${index}`"
            :hide-checkbox="!selectable || hideCheckbox"
            :checkbox-icon="checkboxIcon"
            :checkbox-active-icon="checkboxActiveIcon"
            @click.stop="onClickItem( $event, item )"
            @dblclick="$emit('dblclick:item', { event: $event, item })"
            @keypress.enter="$emit('click:item', { event: $event, item })"
          >
            <template v-slot:checkbox="item">
              <slot name="item.checkbox" v-bind="item"/>
            </template>
            <template
              v-for="head in computedHeaders"
              v-slot:[head.key]="item"
            >
              <slot :name="'item.value.'+head.key" v-bind="item"/>
            </template>
          </data-table-item>
        </slot>
      </template>
    </tbody>

    <tfoot
      v-if="hasAppend"
      class="data-table__footer"
    >
      <tr>
        <td :colspan="totalColumns">
          <slot v-if="!computedItems.length" name="no-data">
            {{ noDataText }}
          </slot>
          <slot name="append"/>
        </td>
      </tr>
    </tfoot>

    <slot/>

  </v-simple-table>
</template>

<script>
import DataTableItem from './DataTableItem';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import {
  wrapInArray,
  convertToUnit,
  getObjectValueByPath,
  deepEqual
} from 'vuetify/lib/util/helpers';

export default {
  name: 'ExplorerList',
  components: { DataTableItem },
  props: {
    ...VSimpleTable.options.props,
    items: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Array,
      default: () => []
    },
    selectable: Boolean,
    hideCheckbox: Boolean,
    hideGlobalCheckbox: Boolean,
    hideHeader: Boolean,
    disableTextSelection: Boolean,
    sortBy: {
      type: Array,
      default: () => []
    },
    disableSort: Boolean,
    singleSort: Boolean,
    minWidth: [ Number, String ],
    itemKey: {
      type: String,
      default: 'id'
    },
    nameKey: {
      type: String,
      default: 'name'
    },
    activeClass: {
      type: String,
      default: '--is-selected'
    },
    checkboxIcon: {
      type: String,
      default: 'mdi-checkbox-blank-circle-outline'
    },
    checkboxActiveIcon: {
      type: String,
      default: 'mdi-checkbox-blank-circle'
    },
    checkboxIndeterminateIcon: {
      type: String,
      default: 'mdi-circle-half-full'
    },
    sortIcon: {
      type: String,
      default: 'mdi-menu-down'
    },
    noDataText: {
      type: String,
      default: 'No data'
    }
  },
  data: () => ({
    computedSort: [],
    selection: {}
  }),
  computed: {
    classes() {
      return {
        noselect: this.disableTextSelection,
        'table--hide-header': this.hideHeader
      }
    },
    computedMinWidth() {
      const minWidth = convertToUnit( this.minWidth );
      if ( ! minWidth ) return null;
      return `--table-min-width:${minWidth}`;
    },
    tableProps() {

      const props = Object.keys( this.$props )
        .filter( key => key in VSimpleTable.options.props )
        .reduce(( obj, key ) => {
          obj[key] = this.$props[key];
          return obj;
        },{});

      if ( this.hideHeader ) {
        props.fixedHeader = false;
      }

      return props;
    },
    computedHeaders() {
      var headers = wrapInArray( this.headers );
      if ( ! headers.length ) {
        headers.push({
          key: this.nameKey,
          text: 'Nombre',
          sorteable: true,
          width: 'auto'
        });
      } else {
        headers = headers.map( head => {
          return {
            text: '',
            sorteable: true,
            ...head,
            width: convertToUnit( head.width ) || 'auto'
          };
        });
      }
      return headers;
    },
    computedItems() {
      return wrapInArray( this.items ).map( item => {
        const id = item[ this.itemKey ];
        return {
          item,
          id,
          selected: !!this.selection[id],
          row: this.computedHeaders.map( column => {

            const { display } = column;
            const cell = {
              item,
              column,
              value: getObjectValueByPath( item, column.key )
            };

            if ( display ) {
              switch ( typeof display ) {
                case 'function':
                  cell.value = display( cell.value, cell, column );
                  break;
                default:
                  cell.value = display[cell.value];
              }
            }

            return cell;
          })
        };
      });
    },
    mapItems() {
      return this.computedItems.reduce(( obj, item ) => {
        obj[item.id] = item;
        return obj;
      },{});
    },
    totalColumns() {
      var num = this.computedHeaders.length || 1;
      if ( this.selectable ) num++;
      return num;
    },
    hasAppend() {
      return !!this.$slots.append || !this.computedItems.length;
    },
    hasSelected() {
      return !!Object.values( this.selection ).length;
    },
    isAllSelected() {
      return this.computedItems.length === Object.values( this.selection ).length;
    }
  },
  watch: {
    disableSort: 'computeSort',
    sortBy( value, old ) {
      if ( ! deepEqual( value, old )) {
        this.computeSort();
      }
    },
    computedSort( value ) {

      value = value
        .slice()
        .sort(( a, b ) => a.index - b.index )
        .filter( v => v.sort );

      this.$emit( 'sort-by', value );
      this.$emit( 'update:sort-by', value.map( v => v.key ));
    },
    selected: {
      immediate: true,
      handler( selected ) {
        this.select( selected );
      }
    },
    selection( value, old ) {
      if ( deepEqual( Object.keys( value ), Object.keys( old ))) return;
      this.$emit( 'selected', Object.values( value ).map( i => i.item ));
    },
    selectable() {
      this.unselect();
    },
    isAllSelected( value )  {
      this.$emit( 'selected:all', value );
    }
  },
  methods: {
    computeSort() {
      this.computedSort = this.computedHeaders.map( h => {

        if ( this.disableSort || ! this.sortBy.length )
          return { value: h.key, key: h.key };

        const index = this.sortBy.findIndex( s => {
          return ( s.key || s ).replace(/^-/,'') === h.key;
        });

        const sort = this.sortBy[index];
        const key = sort ? ( sort.key || sort ) : h.key;
        const mode = key.charAt(0) === '-' ? 'asc' : 'desc';

        return { index, value: h.key, sort: !!sort, key, mode };
      });
    },
    toggleSort( event, head, index ) {


      if ( ! head.sorteable || this.disableSort ) return;
      var s = { ...this.computedSort[index] };

      event.stopPropagation();
      if ( event.type !== 'keypress' ) {
        event.target.blur();
      }

      s.index = this.sortBy[s.index]
        ? s.index
        : this.sortBy.length;

      if ( ! s.sort ) {
        s.sort = true;
        s.mode = 'desc';
        s.key = s.value;
      } else if ( s.mode == 'desc' ) {
        s.mode = 'asc';
        s.key = '-' + s.value;
      } else {
        s.sort = false;
        s.mode = 'desc';
        s.key = s.value;
        s.index = -1;
      }

      if ( this.singleSort )
        this.computedSort = this.computedSort.map(({ key }) => ({ key }));

      this.computedSort.splice( index, 1, s );
    },
    headerClasses( head, index ) {
      var s = this.computedSort[index];
      var sorteable = !this.disableSort && head.sorteable;
      const classes = {
        '--sorteable': sorteable,
        '--sorted': sorteable && s && s.sort,
        [`text-${head.align}`]: head.align
      };
      s && s.sort && (classes['--'+s.mode] = true );
      return classes;
    },
    onClickItem( event, item ) {
      var path = event.path || (event.composedPath && event.composedPath());

      const toggle = event.ctrlKey || path.some( n => {
        return n.classList && n.classList.contains('data-table-item-checkbox');
      });

      this.selectable && this.select(
        item.item,
        toggle ? !item.selected : true,
        !toggle
      );

      this.$emit( 'click:item', { event, item });
    },
    /*** @public ***/
    selectAll() {
      if ( this.isAllSelected ) {
        this.unselect();
      } else {
        this.select( this.items );
      }
    },
    /*** @public ***/
    select( items, value = true, single ) {

      const selection = single ? {} : { ...this.selection };

      if ( single ) // Unselect all
        Object.values( this.selection ).forEach( i => i.selected = false );

      items = wrapInArray( items );
      items.forEach( item => {

        item = this.mapItems[ item[ this.itemKey ]];
        if ( ! item ) return;

        item.selected = value;
        if ( value ) selection[item.id] = item;
        else delete selection[item.id];
      });

      this.selection = selection;
    },
    /*** @public ***/
    unselect( items ) {

      var selection = {};
      if ( ! items ) { // Unselect all

        Object.values( this.selection )
          .forEach( i => i.selected = false );

      } else {

        selection = { ...this.selection };
        wrapInArray( items ).forEach( item => {

          item = this.mapItems[ item[ this.itemKey ]];
          if ( ! item ) return;

          item.selected = false;
          delete selection[item.id];
        });
      }

      this.selection = selection;
    },
  },
  beforeMount() {
    this.select( this.selected );
    this.computeSort();
  }
}
</script>

<style>
.data-table {
  --table-min-width: none;
}
.data-table table {
  min-width: var(--table-min-width);
  position: relative;
}
.data-table {
  background-color: transparent !important;
}
.data-table th {
  background-color: var(--v-background-base) !important;
}
.data-table__checkbox {
  width: 44px;
  padding-right: 0 !important;
}
.data-table__checkbox .v-icon {
  color: var(--v-primary-base);
}
.data-table .append,
.data-table .no-data {
  position: absolute;
  width: 100%;
  padding: 16px;
}
.data-table th.--sorteable {
  cursor: pointer;
}
.data-table th.--sorteable:hover {
  background-color: #eeeeee !important;
}
.data-table th .v-icon.data-table__sort-icon {
  display: none;
  transition: transform .2s ease;
  color: inherit;
}
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  white-space: nowrap;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th.--sorted,
.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr > th.--sorted {
  color: var(--v-primary-base);
}
.data-table th.--sorted .v-icon.data-table__sort-icon {
  display: inline-flex;
  position: absolute;
}
.data-table th.--asc .v-icon.data-table__sort-icon {
  display: inline-flex;
  transform: rotate(180deg);
}
</style>
