<template>
  <table-view
    ref="table"
    api="categoryFilterItem"
    v-model="dialog"
    :filters="filters"
    :headers="headers"
    :popup="popup"
    :save="save"
    :edit="edit"
    @click:item="edit( $event.item.item )"
    selectable
  >
  <template v-slot:item.value.name="{ value, item }">

    <span :class="{ 'font-weight-medium': isMobile }">
      {{ value }}
    </span>
    <template v-if="isMobile">
      <br/>
      <span class="caption">
        {{ item.categoryFilterName }}
      </span>
    </template>
  </template>

  <template v-slot:item.value.picture="{ value }">

    <d-image
      :value="value"
      :width="imageDimension"
      :height="imageDimension"
      :color="value ? 'transparent' : undefined"
      item-image="url"
      hide-placeholder
      outlined
      contain
    />

  </template>
  <template v-slot:item.value.idCategoryFilter="{ item }">

    <category-filter
      :item="item"
      item-text="categoryFilterName"
      item-value="idCategoryFilter"
      small link
    />

  </template>
  <template v-slot:form>

    <category-filter-item-form
      ref="form"
      v-model="model"
      :save="save"
    />

  </template>
  <template v-slot:footer>

    <v-spacer/>

    <btn
      class="mr-2"
      color="primary"
      :loading="exporting"
      @click.stop="exportData"
    >
      {{ $t('btn.export') }}
    </btn>

    <btn @click.stop="edit()">
      {{ $t('categoryFilterItem.btn.new') }}
    </btn>

  </template>

  </table-view>
</template>

<script>
import TableView from '@/components/TableView';
import CategoryFilter from '@/components/values/CategoryFilter';
import DImage from '@/components/Image';
import CategoryFilterItemForm from './Form';
import { ICONS } from '@/utils/constants';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'CategoryFilterItems',
  auth: true,
  head: vm => ({ title: vm.$i18n.t('categoryFilterItem.title') }),
  layout: 'admin',
  layoutProps: ({ i18n }) => ({
    title: i18n.t('categoryFilterItem.title'),
    icon: ICONS.categoryFilterItem
  }),
  components: {
    CategoryFilterItemForm,
    CategoryFilter,
    TableView,
    DImage
  },
  data: () => ({
    model: {},
    dialog: false,
    exporting: false,
    filters: [],
    popup: {}
  }),
  computed: {
    ...mapState( 'app', [ 'isMobile' ]),
    ...mapGetters( 'app', [ 'imageDimension' ]),
    headers() {
      return [
        {
          key: 'picture',
          text: this.$t('inputs.picture.label'),
          mobile: true,
          display: v => v ? v.thumbnailUrl : '',
          width: this.imageDimension,
          sorteable: false,
          filter: false
        },
        {
          key: 'name',
          text: this.$t('inputs.name.label'),
          mobile: true,
        },
        {
          key: 'idCategoryFilter',
          text: this.$t('inputs.idCategoryFilter.label'),
          filter: {
            is: 'api-field',
            key: 'categoryFilter',
            api: 'categoryFilter',
            itemSearch: 'name',
            clearable: true,
            multiple: true
          }
        }
      ];
    },
  },
  methods: {
    edit( item ) {

      this.dialog = true;
      this.popup = {
        ...this.popup,
        title: this.$t(`categoryFilterItem.btn.${ item ? 'edit': 'new' }`)
      };

      if ( item ) {
        this.$router.replace({
          path: this.$route.path,
          params: this.$route.params,
          query: { ...this.$route.query, id: item.id }
        }).catch(() => {});
      }

      this.$nextTick(() => {
        this.$refs.form.resetValidation();
        this.model = {
          id: -1,
          status: 1,
          ...item
        };
      });
    },
    exportData() {
      if ( this.exporting ) return;
      this.exporting = true;
      this.$refs.table.exportData().finally(() => {
        this.exporting = false;
      });
    },
    sanitize( model ) {
      return {
        ...model,
        categoryFilter: model.idCategoryFilter,
        idCategoryFilter: null,
        picture: null,
        pictureId: model.picture
          ? model.picture.id
          : null
      };
    },
    save() {
      if ( this.$refs.form.validate()) {
        this.dialog = false;
        this.model = this.$refs.form.model;
        this.$store
          .dispatch('api/categoryFilterItem/set', this.sanitize( this.model ))
          .then(() => this.$refs.table.refresh())
          .then(() => {
            this.$store.dispatch( 'app/success', {
              message: this.$t('alerts.save')
            });
          }).catch( err => {
            this.$store.dispatch( 'app/error', {
              message: err.message
            });
          });
      }
    }
  }
}
</script>
