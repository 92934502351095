<template>
  <m-dialog
    v-model="dialog"
    v-bind="$attrs"
    :title="title||$t('alerts.removeTitle')"
    :max-width="maxWidth"
    @click:accept="onRemove"
    @click:cancel="onCancel"
    actions
  >
    <slot>
      <p v-html="text || $t('alerts.removeText')"/>
    </slot>
  </m-dialog>
</template>

<script>
export default {
  props: {
    value: null,
    api: String,
    items: {
      type: Array,
      default: () => []
    },
    title: String,
    text: String,
    maxWidth: {
      type: [ String, Number ],
      default: 540
    }
  },
  data() {
    return {
      dialog: !!this.value
    }
  },
  watch: {
    value( value ) {
      this.dialog = !!value;
    },
    dialog( value ) {
      this.$emit( 'input', value );
    }
  },
  methods: {
    onRemove() {

      if ( this.$listeners['click:accept'] )
        return this.$emit( 'click:accept', this.items );

      this.dialog = false;
      if ( ! this.items.length || ! this.api ) return;

      let path = `api/${this.api}/remove`;
      let payload = { id: this.items[0].id };

      if ( this.items.length > 1 ) {
        path += 'All';
        payload = { ids: this.items.map( i => i.id ) };
      }

      return this.$store
        .dispatch( path, payload )
        .then( res => {
          this.$emit( 'removed', res );
        })
        .catch(() => {
          this.$store.dispatch( 'app/alert', {
            type: 'error',
            message: this.$t('alerts.removeError')
          });
        });
    },
    onCancel() {
      if ( this.$listeners['click:cancel'] ) {
        this.$emit( 'click:cancel', this.items );
      } else {
        this.dialog = false;
      }
    }
  }
}
</script>
