<script>
import VPagination from 'vuetify/lib/components/VPagination';
import VIcon from 'vuetify/lib/components/VIcon';
import { getSlot } from 'vuetify/lib/util/helpers';

export default {
  name: 'Pagination',
  mixins: [ VPagination ],
  props: VPagination.options.props,
  methods: {
    genIcon( h, icon, disabled, fn, label ) {
      return h('li', [ getSlot( this, icon === this.nextIcon ? 'next' : 'prev', {
        on: disabled ? {} : { click: fn },
        icon,
        disabled
      }) || h('button', {
        staticClass: 'v-pagination__navigation',
        class: {
          'v-pagination__navigation--disabled': disabled
        },
        attrs: {
          type: 'button',
          'aria-label': label
        },
        on: disabled ? {} : {
          click: fn
        }
      }, [h(VIcon, [icon])])]);
    },
    genItems(h) {
      return this.items.map((i, index) => {
        return h('li', {
          key: index
        }, [isNaN(Number(i)) ? h('span', {
          class: 'v-pagination__more'
        }, [i.toString()]) : getSlot( this, 'item', {
          value: i,
          current: i === this.value,
          on: { click: () => this.$emit('input', i) }
        }) || this.genItem(h, i)]);
      });
    }
  },
  render(h) {
    return VPagination.options.render.call( this, h );
  }
}
</script>
