var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{ref:"table",attrs:{"api":"categoryFilterItem","filters":_vm.filters,"headers":_vm.headers,"popup":_vm.popup,"save":_vm.save,"edit":_vm.edit,"selectable":""},on:{"click:item":function($event){return _vm.edit( $event.item.item )}},scopedSlots:_vm._u([{key:"item.value.name",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('span',{class:{ 'font-weight-medium': _vm.isMobile }},[_vm._v(" "+_vm._s(value)+" ")]),(_vm.isMobile)?[_c('br'),_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.categoryFilterName)+" ")])]:_vm._e()]}},{key:"item.value.picture",fn:function(ref){
var value = ref.value;
return [_c('d-image',{attrs:{"value":value,"width":_vm.imageDimension,"height":_vm.imageDimension,"color":value ? 'transparent' : undefined,"item-image":"url","hide-placeholder":"","outlined":"","contain":""}})]}},{key:"item.value.idCategoryFilter",fn:function(ref){
var item = ref.item;
return [_c('category-filter',{attrs:{"item":item,"item-text":"categoryFilterName","item-value":"idCategoryFilter","small":"","link":""}})]}},{key:"form",fn:function(){return [_c('category-filter-item-form',{ref:"form",attrs:{"save":_vm.save},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]},proxy:true},{key:"footer",fn:function(){return [_c('v-spacer'),_c('btn',{staticClass:"mr-2",attrs:{"color":"primary","loading":_vm.exporting},on:{"click":function($event){$event.stopPropagation();return _vm.exportData.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('btn.export'))+" ")]),_c('btn',{on:{"click":function($event){$event.stopPropagation();return _vm.edit()}}},[_vm._v(" "+_vm._s(_vm.$t('categoryFilterItem.btn.new'))+" ")])]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})}
var staticRenderFns = []

export { render, staticRenderFns }