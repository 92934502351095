<template>
  <text-field
    type="text"
    class="file-field"
    :value="fileName"
    :append-icon="computedUploadButton"
    :loading="loading || internalLoading"
    :error="error || !!errors"
    :error-messages="errors"
    v-bind="$props"
    v-on="listeners"
    readonlyx
  >

    <template v-for="name in slots" :slot="name">
      <slot :name="name"/>
    </template>

    <template v-for="name in scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData"/>
    </template>

    <template v-slot:append>
      <slot name="append"/>
    </template>

    <template v-slot:extend>
      <input
        v-show="false"
        ref="file"
        type="file"
        :accept="accept"
        :multiple="multiple"
        @change="onChange"
      />
    </template>

  </text-field>
</template>

<script>
import TextField from '../TextField';
//import { wrapInArray } from 'vuetify/lib/util/helpers';
import { componentProps } from '@/utils';
import config from './defaults';

const defaultProps = {
  ...TextField.props,
  accept: String,
  upload: Function,
  autoUpload: Boolean,
  multiple: Boolean,
  errorMessage: {
    type: String,
    default: 'Ha ocurrido un error al subir el archivo.'
  },
  uploadIcon: {
    type: String,
    default: 'mdi-upload'
  }
};

delete defaultProps.type;
delete defaultProps.prevent;
delete defaultProps.appendIcon;

export default {
  name: 'FileField',
  inheritAttrs: false,
  components: { TextField },
  props: componentProps( defaultProps, config ),
  data() {
    return {
      slots: [
        'append-outer',
        'prepend',
        'prepend-inner',
        'progress'
      ],
      scopedSlots: [
        'message',
        'counter'
      ],
      files: [],
      index: -1,
      fileName: this.value,
      internalLoading: false,
      internalError: false
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        click: this.click,
        'click:append': this.startUpload,
        'click:clear': this.clear,
        input: () => {},
      }
    },
    errors() {
      if ( this.errorMessages.length ) return this.errorMessages;
      if ( this.internalError ) return [ this.errorMessage ];
      return null;
    },
    computedUploadButton() {
      return ! this.autoUpload && this.files.length || this.internalError
        ? this.uploadIcon
        : '';
    }
  },
  watch: {
    value( value ) {
      this.fileName = value;
    }
  },
  methods: {
    click(e) {
      if ( this.internalLoading ) return;
      this.$emit( 'click', e );
      this.$refs.file && this.$refs.file.click();
    },
    onInput( value ) {
      this.files = [];
      this.fileName = value;
      this.$emit( 'input', value );
    },
    clear( e, index ) {
      if ( ! index ) this.file = undefined;
      else if ( this.file && this.file[index] ) {
        this.file.splice( index, 1 );
        if ( ! this.file.length ) this.file = undefined;
      }
      this.internalError = false;
      this.getFileName();
      this.$emit( 'click:clear', e );
      this.$emit( 'input', this.fileName );
    },
    onChange() {

      if ( ! this.$refs.file ) return;

      this.files = [].slice.call( this.$refs.file.files );
      this.getFileName();
      this.$emit( 'change', this.files );

      this.$refs.file.value = null;
      this.autoUpload && this.startUpload();
    },
    startUpload() {

      if ( ! this.files.length ) return;

      if ( this.upload ) {

        const data = new FormData();

        this.files.forEach( file => {
          data.append( 'file', file );
        });

        this.internalLoading = true;
        this.internalError = false;

        Promise
          .resolve( this.upload( data, this.files ))
          .then( fileName => this.onInput( fileName || this.getFileName()))
          .catch( err => {
            console.error( err );
            this.internalError = true;
            this.$emit( 'error', err );
          })
          .finally(() => this.internalLoading = false );

      } else {
        console.warn( new Error('No se ha especificado una función de subida de archivo.'));
      }
    },
    getFileName() {
      return this.files.map( file => file.name ).join(', ');
    }
  },
  beforeMount() {
    this.internalError = false;
  }
};
</script>
