<template>
  <v-checkbox
    class="checkbox"
    :class="sizeableClasses"
    v-bind="$props"
    v-on="$listeners"
    @change="$emit('input',$event)"
  >

    <template v-for="name in slots" :slot="name">
      <slot :name="name"/>
    </template>

    <template v-for="name in scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData"/>
    </template>

  </v-checkbox>
</template>

<script>
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import sizeable from 'vuetify/lib/mixins/sizeable';
import { componentProps } from '@/utils';
import config from './defaults';

const defaultProps = {
  ...VCheckbox.options.props,
  ...sizeable.options.props
};

export default {
  mixins: [ sizeable ],
  props: componentProps( defaultProps, config ),
  data() {
    return {
      slots: [ 'append', 'default', 'label', 'prepend' ],
      scopedSlots: [ 'message' ]
    }
  }
}
</script>

<style lang="scss">

$sizes: (
  'default': 16px,
  'x-large': 22px,
  'large': 18px,
  'small': 14px,
  'x-small': 12px
);

.checkbox {
  .v-label {
    font-size: inherit;
  }
  .v-input--selection-controls__input .v-icon {
    font-size: 150%;
  }
  @each $name, $size in $sizes {
    &.v-size--#{$name} {
      font-size: $size;
      .v-input--selection-controls__input {
        width: $size * 1.5;
        height: $size * 1.5;
      }
      .v-label {
        height: $size * 1.5;
        line-height: $size * 1.5;
      }
    }
  }
}
.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: .5em;
}
</style>
